/*!
 *  VARIABLES
 */

//// Grid settings
//$main-sm-columns:       12;
//$sidebar-sm-columns:    4;

/* ---------------------------- Whith & Height ----------------------------------- */
$full: 100%;
$medium: 50%;
$small: 25%;

/* ---------------------------- Margin ----------------------------------- */
$margin-auto: auto auto;
$margin-row-left-none: 0 !important;
$margin-row-right-none: 0 !important;
$no-margin: 0;

/* ---------------------------- Padding ----------------------------------- */
$padding-auto: auto auto;
$padding-container-fluid-left: 5px !important;
$padding-container-fluid-right: 5px !important;
$no-padding: 0;

/* ---------------------------- Colors ----------------------------------- */
$background-body: #FFFFFF;
$institutional: #00C3B4 !default;
$gradient: 45deg, rgb(234,244,129) 0%, rgb(149,237,165) 49%, rgb(71,230,198) 100%; // normal gradient used in each section
$gradient-footer: 45deg, rgb(194,242,128) 0%, rgb(130,235,173) 50%, rgb(71,230,198) 100%; // footer radient
$gradient-step: 130deg, rgb(194,242,128) 0%, rgb(130,235,173) 50%, rgb(71,230,198) 100%; // approccio page
$gradient-chi-siamo: 150deg, rgb(194,242,128) 0%, rgb(130,235,173) 50%, rgb(71,230,198) 100%; // chi siamo page
$gradient-menu: 260deg, rgb(194,242,128) 0%, rgb(130,235,173) 50%, rgb(71,230,198) 100%; // menu gradient
$text-green: #00B98C;
$bold-text : #016C64;


/* ---------------------------- FONT ----------------------------------- */
$merryweather : 'Merriweather', serif;
//$playfair : 'Playfair Display', serif;

$white: #FFFFFF;
$black: #000000;
$grey1: #333333;
$grey2: #666666;
$grey3: #999999;
$grey4: #AAAAAA;
$grey5: #CCCCCC;
$grey6: #DDDDDD;
$grey7: #EEEEEE;
$grey8: #F0F0F0;
$grey9: #F9F9F9;
$grey10: #FCFCFC;

$success: #73A142;
$success-opacity: rgba(115,161,66,0.5);
$alert: #C99B37;
$alert-opacity: rgba(201, 155, 55, 0.50);
$error: #BE3D2F;
$error-opacity: rgba(190,61,47,0.50);

/* ---------------------------- Align text ----------------------------------- */
$center: center;
$left: left;
$right: right;
$v-center: middle;

//* ---------------------------- Tipography ----------------------------------- */

// font-size px
$font-size-base:  12px !default;
$font-size-32:    ceil(($font-size-base * 2.6));    // ~32px
$font-size-30:    ceil(($font-size-base * 2.5));    // ~30px
$font-size-28:    ceil(($font-size-base * 2.3));    // ~28px
$font-size-24:    ceil(($font-size-base * 2));      // ~24px
$font-size-22:    ceil(($font-size-base * 1.8));    // ~22px
$font-size-21:    ceil(($font-size-base * 1.7));    // ~21px
$font-size-20:    ceil(($font-size-base * 1.6));    // ~20px
$font-size-18:    ceil(($font-size-base * 1.5));    // ~18px
$font-size-16:    ceil(($font-size-base * 1.3));    // ~16px
$font-size-14:    ceil(($font-size-base * 1.1));    // ~14px
$font-size-10:    ceil(($font-size-base * 0.80));   // ~10px
$font-size-9:     ceil(($font-size-base * 0.70));   // ~9px

// font-size em
$em-8px:  0.500em;
$em-9px:  0.563em;
$em-10px: 0.625em;
$em-11px: 0.688em;
$em-12px: 0.750em;
$em-13px: 0.813em;
$em-14px: 0.875em;
$em-15px: 0.938em;
$em-16px: 1em !default;
$em-18px: 1.125em;
$em-20px: 1.250em;
$em-22px: 1.375em;
$em-24px: 1.500em;
$em-26px: 1.625em;
$em-28px: 1.750em;
$em-30px: 1.875em;
$em-32px: 2em;
$em-34px: 2.125em;
$em-36px: 2.25em;
$em-38px: 2.375em;
$em-40px: 2.5em;

// font-size rem
$rem-8px:  0.5rem;
$rem-9px:  0.5625rem;
$rem-10px: 0.625rem;
$rem-11px: 0.6875rem;
$rem-12px: 0.75rem;
$rem-13px: 0.8125rem;
$rem-14px: 0.875rem;
$rem-15px: 0.9375rem;
$rem-16px: 1rem !default;
$rem-17px: 1.063rem;
$rem-18px: 1.125rem;
$rem-20px: 1.25rem;
$rem-21px: 1.313rem;
$rem-22px: 1.375rem;
$rem-24px: 1.5rem;
$rem-26px: 1.625rem;
$rem-28px: 1.75rem;
$rem-30px: 1.875rem;
$rem-32px: 2rem;
$rem-34px: 2.125rem;
$rem-36px: 2.25rem;
$rem-38px: 2.375rem;
$rem-40px: 2.5rem;
