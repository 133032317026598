/*!
 *  PAGES
 */

main {
  width: $full;
  height: auto;
  overflow-x:hidden;
  position: relative;

  .absolute-pos {
    @include media-breakpoint-up(xl) {
      position: fixed;
    }
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    padding-bottom: 10px;
    z-index: 99;

    .list-social {
      padding-left: 25px;
      position: absolute;
      top: 50%;
      width: 40px;
      height: 180px;
      margin-top: -90px;

      @media (max-width: 1300px) and (min-width: 1100px){
        padding-left: 8px;
      }
      li {
        margin: 15px 0px;
        a  {
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .hero {
    @extend .extend-hero;
    min-height: 100vh;
    position: relative;
    opacity: 1;

    &:before {
      content: "";
      opacity: 0;
      width: $full;
      height: $full;
      position: absolute;
      top:0;
      left: 0;
      background-color: #7be6b2;
      z-index: 2;
    }

    .bk {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: 0;
      left: 0;
      width: $full;
      height: $full;
    }

    .wrapp-scene {
      position: relative;
      top: -80px;
      left: 0px;
      width: $full;
      height: 100%;
      min-height: 100vh;
      overflow: hidden;

      .scene {
          width: $full;
          max-height: 100%;
          position: relative;
          margin: 30px 0px;
          //top: 110px;

          .scene-wrapper {
            position: absolute;
            width: $full;

            img {
              margin: 0 auto;
              @include media-breakpoint-down(sm) {
                @include transform(scale(1.4));
              }
              @media (max-width : 380px) {
                @include transform(scale(2.4));
              }
            }
          }

          .moon {
              position: relative;
              z-index: 1;
          }

          .cloud {
              -webkit-animation: cloud 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
              -moz-animation: cloud 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
              animation: cloud 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
              position: relative;
              z-index: 2;
          }
          .wrapp-txt {
            height: 300px;
            .text {
                position: relative;
                left: 27%;
                top: 4%;
                @include media-breakpoint-down(md) {
                  left: 25%;
                  top: 5%;
                }
                @include media-breakpoint-down(sm) {
                  left: 20%;
                  top: -15%;
                }
                @include media-breakpoint-down(xs) {
                  left: 20%;
                  top: -13%;
                }
                @media (max-width : 380px) {
                  left: 10%;
                  top: -25%;
                }
                z-index: 3;


                h1 {
                  font-size: $rem-40px + $rem-40px;
                  color: $white;
                  font-weight: 300;
                  margin-bottom: 0;
                  line-height: 1;
                  text-transform: uppercase;

                  @include media-breakpoint-down(md) {
                    font-size: $rem-40px + $rem-20px;
                  }
                  @include media-breakpoint-down(sm) {
                    font-size: $rem-40px + $rem-8px;
                  }
                  @include media-breakpoint-down(xs) {
                    font-size: $rem-30px + $rem-16px;
                  }
                  @media (max-width : 380px) {
                    font-size: $rem-34px;
                  }

                  strong {
                    font-weight: 600;
                    color: $white;
                  }
                }
                h5 {
                  font-size: $rem-16px;
                  @extend .semibold;
                  margin-bottom: 10px;

                  @include media-breakpoint-down(md) {
                    font-size: $rem-14px;
                  }
                  @include media-breakpoint-down(sm) {
                    font-size: $rem-12px;
                  }
                  @include media-breakpoint-down(xs) {
                    font-size: $rem-10px;
                  }
                  @media (max-width : 380px) {
                    font-size: $rem-8px;
                  }
                }
            }
          }

          .mountain {
              position: relative;
              z-index: 4;
          }

          .home {
              position: relative;
              z-index: 5;
          }

          .ballon {
              -webkit-animation: ballon 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
              -moz-animation: ballon 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
              animation: ballon 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
              position: relative;
              top: 60px;
              left: 15%;
              @include media-breakpoint-down(md) {
                left: 8%;
              }
              @include media-breakpoint-down(sm) {
                left: 5%;
              }
              z-index: 7;
          }

          .ballon img {
              max-width: 80px;
              @include media-breakpoint-down(md) {
                max-width: 60px;
              }
              @include media-breakpoint-down(sm) {
                max-width: 40px;
              }
              @include media-breakpoint-down(xs) {
                max-width: 20px;
              }
          }

          .plane {
            -webkit-animation: ballon 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
            -moz-animation: ballon 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
            animation: ballon 4s 0.1s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
          }
      }
    }
  }

  .scroll {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    padding-bottom: 10px;
    padding-right: 20px;
    z-index: 9;
    .scroll-down {
      position: absolute;
      top: 50%;
      right: 20px;
      height: 100px;
      width: 20px;
      margin-top: -50px;

      .change-scroll {
        fill: none;
        // stroke: #f48005;
        stroke: $bold-text;
        stroke-width: 2px;
      }
      .text {
        writing-mode: vertical-lr;
        margin-top: 30px;
        @extend .bold;
        text-transform: uppercase;
        color: $bold-text;
        font-size: $rem-12px;
      }
      &.changed {
        position: fixed;
        top:20px;
        .change-scroll {
          stroke: $institutional;
        }
        .text {
          color: $institutional;
        }
      }
    }
  }

  section {
    padding: 80px 0px;

    .title * {
      @extend .semibold;
    }
  }

  // index
  &.home {
    section {
      &.target{

        .content-target {
          margin: 30px 0px;
          .artwork {
            padding: 50px 0px;
            .bk-round {
              position: relative;
              width: $full;

              #circular , #ellipsis {

                .cls-1, .cls-3 {
                  fill: none;
                  stroke-width: 1px;
                }

                .cls-1 {
                  stroke: #d3d3d3;
                  stroke-width: 1px;
                }

                .cls-2 {
                  stroke-width: 1px;
                }
              }

              .txt {
                font-size: $rem-26px;
                position: absolute;
                top: 45%;
                width: $full;
                text-align: center;
                @extend .bold;
                color: $institutional;
                @include media-breakpoint-down(md){
                  font-size: $rem-22px;
                }
                @include media-breakpoint-down(sm){
                  font-size: $rem-18px;
                }

                .cursive {
                  @extend .regular;
                  font-size: $rem-20px;
                  @include media-breakpoint-down(sm){
                    font-size: $rem-16px;
                  }
                }
              }
            }
          }
        }
      }
      &.what-we-do{
        @include background($gradient);

          .subtitle {
            margin-top: 10px;
            h4 {
              color: $white;
              @extend .semibold;
              @include media-breakpoint-down(md){
                font-size: $rem-16px;
                margin-bottom: 10px;
              }
              @include media-breakpoint-down(sm){
                font-size: $rem-14px;
              }
            }
          }

          .title {
            h2 {
              color: $white;
              font-weight: 300;
              @include media-breakpoint-down(md){
                font-size: $rem-30px;
              }
              @include media-breakpoint-down(sm){
                font-size: $rem-28px;
              }
            }
          }

          .wrapp-info {
            .separator {
                position: relative;
                margin: 50px 0px 30px;
                width: 100%;
                height: 1px;
                background: $white;
            }

            .title {
              h3 {
                color: $institutional;
                @extend .semibold;
                letter-spacing: 1.4px;
              }
            }

            .count {
              color: $institutional;
              font-size: $rem-40px + $rem-8px;
              @include media-breakpoint-down(md){
                font-size: $rem-40px;
              }
              @include media-breakpoint-down(sm){
                font-size: $rem-36px;
              }
              @extend .light;
            }

            .info {
              margin-top: 40px;
              .list {
                p {
                  font-size: $rem-18px;
                  color: $white;
                  margin-bottom: 15px;
                  @extend .semibold;
                  @include media-breakpoint-down(md){
                    font-size: $rem-14px;
                  }
                  // @include media-breakpoint-down(sm){
                  //   font-size: $rem-12px;
                  // }
                  .small {
                    display: block;
                    color: $white;
                    width: $full;
                    margin-top: 3px;
                    @extend .light;
                    font-size: $rem-13px!important;
                    @include media-breakpoint-down(md){
                      font-size: $rem-12px!important;
                    }
                    // @include media-breakpoint-down(sm){
                    //   font-size: $rem-12px!important;
                    // }
                  }

                  &:last-of-type {
                    margin-bottom: 0px;
                  }
                }
              }

              .more {
                img {
                  width: 35px;
                  height: 35px;
                  @include media-breakpoint-down(sm) {
                    margin-top: 40px;
                    width: 30px;
                    height: 30px;
                  }
                }
              }
            }
          }
      }
      &.project{

        .more {
          a {
            .btnn-read-more {
              background-color: $institutional;
            }
          }
        }
        @extend .extend-project-section;
      }
      &.client{

        @include media-breakpoint-down(md) {
          padding: 0px 0px 80px;
        }

        .wrapp-all-client {
          margin-top: 60px;
          @include media-breakpoint-down(sm) {
            margin-top: 30px;
          }
          .wrapp-client {
            .wrapp-image {
              text-align: center;
              height: 90px;
              position: relative;
              @include media-breakpoint-down(sm) {
                height: 110px;
                margin-bottom: 10px;
              }
              img {
                width: 80%;
                @include media-breakpoint-down(sm) {
                  width: 90%;
                }
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
  }

  // services
  &.services {
    section.services-tag {
      .tag-list {
        margin: 30px 0 0 0;
      }
    }
    section.services-projects {
      .project {
        height: 400px;
        width: $full;
        position: relative;

        .background-images {
          @extend .extend-background-image;
        }
      }
    }
  }
  // approccio
  &.approccio {
    .normal {
      font-size: $rem-14px;
    }
    .dest-explaination {

      .wrapp-title {
        color: $institutional;
        .title {
          @extend .semibold;
          @include media-breakpoint-down(sm) {
            font-size: $rem-34px;
          }
        }
      }
      .subtitle {
        @extend .light;
        color: $grey3;
        margin-top: 30px;
        font-size: $rem-18px;
      }
      .content-text {
        margin-top: 70px;
        p {
          line-height: 1.7;
          @include media-breakpoint-down(sm) {
            font-size: $rem-14px;
          }
        }
      }
      .exit-title {
        .title {
          color: $bold-text;
          @extend .semibold;
          margin-top: 70px;
        }
      }
    }


    .the-step {
      position: relative;

      @include media-breakpoint-down(md) {
        padding: 100px 0px 0px;
      }

      #bk-anim {
        @include background($gradient-step);
        background-position: bottom;
        position: absolute;
        top: 0;
        left: 0px;
        width: $full;
        height: $full;
      }
      .swiper-container {

        .swiper-wrapper {
          .swiper-slide {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;


            .row {
              display: block!important;
            }

            //SINGULAR
            &.first {
                .wrapp-text {

                  .intro {
                    margin-top: 50px;
                    margin-bottom: 10px;
                  }
                  .analisis {
                    li {
                      margin-bottom: 10px;
                      span {
                        width: 10px;
                        height: 2px;
                        background: $white;
                        margin-right: 5px;
                      }
                      p {
                          margin-bottom: 0px;
                      }
                    }
                  }
                }
              }
            &.first, &.three {
              .subtitle * {
                color: $white;
              }
              .title {
                color: $white;
              }
              .wrapp-text * {
                color: $white;
              }
            }
            &.second{
              .row {
                @include media-breakpoint-down(md){
                  padding: 70px 0px 0px;
                }
              }
              .subtitle * {
                color: $institutional;
              }
              .title {
                color: $institutional;
              }
              .wrapp-text * {
                color: $grey2;
              }
              .wrapp-text {
                .to-top {
                  margin-top: 40px;
                }
              }
            }
            &.three {
              .wrapp-text {
                .to-top {
                  margin-top: 40px;
                  &:first-of-type {
                    margin-top: 0px;
                  }
                }
              }
            }

            // GENERAL LAYOUT
            .subtitle {
              text-transform: uppercase;
              font-size: $rem-14px;
              @include media-breakpoint-down(sm) {
                font-size: $rem-12px;
              }
            }
            .title {
              @extend .semibold;
              font-size: $rem-40px + $rem-16px;
              @include media-breakpoint-down(sm) {
                font-size: $rem-40px + $rem-8px;
              }
              @include media-breakpoint-down(xs) {
                font-size: $rem-30px;
              }
            }

            .wrapp-text {
              margin-top: 50px;

              p {
                font-size: $rem-17px;
                @include media-breakpoint-down(sm) {
                  font-size: $rem-14px;
                }
                @include media-breakpoint-down(xs) {
                  font-size: $rem-13px;
                }
              }
            }
          }
        }

        .custom-pagination {
          @include media-breakpoint-down(sm) {
            top: 5%;
          }
          .wrapp-bullet {
            margin: 10px 0px 0px;
            width: auto;
            height: auto;
            background: transparent;
            opacity: 1;
            &:first-of-type {
              margin-top: 0px;
            }


            .number {
              color: $white;
              margin-right: 10px;
              font-size: $rem-20px;
              @include media-breakpoint-down(sm) {
                font-size: $rem-17px;
              }
            }

            .line {
              width: 20px;
              height: 3px;
              @include media-breakpoint-down(sm) {
                height: 1px;
              }
              background: $white;
              opacity: 1;
              @include transition(all 0.4s ease-in-out);
            }

              &.swiper-pagination-bullet-active {
                  opacity: 1;
                  background: transparent;
                  @include transition(all 0.4s ease-in-out);
                  .line {
                    width: 40px;
                    height: 3px;
                    background: $white;
                    opacity: 1;
                    @include transition(all 0.4s ease-in-out);
                  }
              }

            &.institutionl-change {
              .number {
                color: $institutional;
              }
              .line {
                background: $institutional;
              }
            }
          }
        }
      }

      .wrapp-steps {
        .step {
          padding: 80px 15px;

          .subtitle {
            text-transform: uppercase;
            font-size: $rem-14px;
            @include media-breakpoint-down(sm) {
              font-size: $rem-12px;
            }
          }
          .title {
            @extend .semibold;
            font-size: $rem-40px + $rem-16px;
            @include media-breakpoint-down(sm) {
              font-size: $rem-40px + $rem-8px;
            }
            @include media-breakpoint-down(xs) {
              font-size: $rem-30px;
            }
          }
          .wrapp-text {
            margin-top: 50px;

            p {
              font-size: $rem-17px;
              @include media-breakpoint-down(sm) {
                font-size: $rem-14px;
              }
              @include media-breakpoint-down(xs) {
                font-size: $rem-13px;
              }
            }
          }

          //SINGULAR
          &.first {
              .wrapp-text {
                .intro {
                  margin-top: 50px;
                  margin-bottom: 10px;
                }
                .analisis {
                  li {
                    margin-bottom: 10px;
                    span {
                      width: 10px;
                      height: 2px;
                      background: $white;
                      margin-right: 5px;
                    }
                    p {
                        margin-bottom: 0px;
                    }
                  }
                }
              }
            }
          &.first, &.three {
            @include background($gradient-step);
            .subtitle * {
              color: $white;
            }
            .title {
              color: $white;
            }
            .wrapp-text * {
              color: $white;
            }
          }
          &.second{
            background: $white;
            .subtitle * {
              color: $institutional;
            }
            .title {
              color: $institutional;
            }
            .wrapp-text * {
              color: $grey2;
            }
            .wrapp-text {
              .to-top {
                margin-top: 40px;
              }
            }
          }
          &.three {
            .wrapp-text {
              .to-top {
                margin-top: 40px;
                &:first-of-type {
                  margin-top: 0px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.cosa-facciamo {

    section {

      &.dest-explaination {

        .wrapp-title {
          color: $institutional;
          .title {
            @extend .semibold;
            @include media-breakpoint-down(sm) {
              font-size: $rem-34px;
            }
          }
        }
        .subtitle {
          @extend .light;
          color: $grey3;
          margin-top: 30px;
          font-size: $rem-18px;
        }
        .content-text {
          margin-top: 70px;
          p {
            line-height: 1.7;
            @include media-breakpoint-down(sm) {
              font-size: $rem-14px;
            }
          }
        }
        //.exit-title {
        //   .title {
        //     color: $bold-text;
        //     @extend .semibold;
        //     margin-top: 70px;
        //   }
        // }
      }

      &.masterplan {
        .intestation {
          .title {
            color: $institutional;
            @extend .semibold;
          }
        }
        .grid-wrapp {
          margin-top: 60px;

          .cnt-mrg {
            margin: 30px 0px;
            .icon {
              img {
                max-width: 70px;
                height: 50px;
              }
            }
            .title {
              margin: 25px 0px;
            }

            .description {
              p {
                line-height: 2;
              }
            }
          }
        }
      }

      &.making {
        position: relative;

        .bk {
          position: absolute;
          top: 0;
          left: 0;
          width: $full;
          height: $full;
          @include background($gradient);
        }

        @extend .masterplan;
        hr {
          background: $white;
        }
        .title {
          color: $white!important;
        }

        .cnt-mrg {
          .title {
            color: $white;
          }
        }

        .description {
          p {
            color: $white;
            font-size: $rem-14px!important;
            letter-spacing: 0.3px;
            strong {
              color: $white;
            }
          }
        }
      }

      &.marketing {
        @extend .masterplan;
      }

      &.project{
        @extend .extend-project-section;
      }

      &.contact-information {
        position: relative;

        .title {
            h2 {
            color: $bold-text;
            @extend .semibold;
            font-size: $rem-34px;

            @include media-breakpoint-down(md) {
              font-size: $rem-30px;
            }
          }
        }

        .subtitle {
          @extend .light;
          margin-top: 10px;
          font-size: $rem-16px;
          @include media-breakpoint-down(md) {
            font-size: $rem-14px;
          }
        }

        .form-contact {
          margin-top: 100px;

          .btn-send {
            background: $institutional;
            color: $white;
            letter-spacing: 1.6;
            padding: 0px 40px;
          }

          div#mce-error-response {
            margin-top: 20px;
            padding: 0px 15px;
            color: #710101;
            font-size: $rem-14px;
            @extend .semibold;

              a {
                color: #710101;
                text-decoration: underline;
              }
          }
          div#mce-success-response {
            margin-top: 20px;
            padding: 0px 15px;
            color: $grey2;
            font-size: $rem-14px;
            @extend .semibold;

              a {
                color: $grey2;
                text-decoration: underline;
              }
          }
        }
      }
    }
  }

  //progetti
  &.projects {

    .full-sec {
      position: relative;
      height: 500px;
      width: $full;

      .background-image {
        @extend .extend-background-image;
      }
    }
    .filter-carousel {
      .filter-list {
        margin: 50px 0px 30px;
        .button {
          background-color: $grey6;
          color: $grey2;
          @include transition(all 0.4s);

          &:first-child {
            margin-left: 0px;
          }
          margin-left: 15px;

          &.is-checked {
            background-color: $grey2;
            color: $white;
            @include transition(all 0.4s);
          }
        }
      }
      .element-item {
        height: 300px;
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;

        .background {
          background: $grey6;
          position: relative;
          top: 0px;
          left: 0px;
          width: $full;
          height: $full;
        }
      }
    }
  }

  // chi-siamo
  &.chi-siamo {

    section {
      &.travel-life {

        .wrapp-title {
          color: $institutional;
          .title {
            @extend .semibold;
            @include media-breakpoint-down(sm) {
              font-size: $rem-34px;
            }
          }
        }
        .subtitle {
          @extend .light;
          color: $grey3;
          margin-top: 30px;
        }
        .content-text {
          margin-top: 70px;
          p {
            line-height: 1.7;
            @include media-breakpoint-down(sm) {
              font-size: $rem-14px;
            }
          }
        }
        .exit-title {
          .title {
            color: $bold-text;
            @extend .semibold;
            margin-top: 70px;
            display: block;
            text-decoration: underline;
          }
        }
      }

      &.the-mission {
        position: relative;
        @include background($gradient-chi-siamo);

        // GENERAL LAYOUT
        .title {
          @extend .semibold;
          font-size: $rem-40px + $rem-16px;
          @include media-breakpoint-down(md) {
            font-size: $rem-40px + $rem-10px;
          }
          @include media-breakpoint-down(sm) {
            font-size: $rem-40px + $rem-8px;
          }
          color: $white;
        }

        .wrapp-text {
          margin-top: 50px;

          p {
            font-size: $rem-17px;
            color: $white;
            strong {
              color: $white;
            }
            @include media-breakpoint-down(md) {
              font-size: $rem-16px;
            }
            @include media-breakpoint-down(sm) {
              font-size: $rem-14px;
            }
          }
        }
      }
    }
    // section.chi-siamo-info {
    //   .text-columns {
    //     // column-count: 2;
    //     margin-bottom: 60px;
    //   }
    //
    //   h6 {
    //     font-weight: 600;
    //   }
    //
    //   .btnn-medium {
    //     float: right;
    //   }
    // }
    //
    // section.chi-siamo-mission {
    //   min-height: 400px;
    //   text-align: center;
    //   background-color: $grey6;
    //
    //   .row {
    //     min-height: calc(400px - 100px);
    //   }
    // }
    //
    // section.chi-siamo-team  {
    //   text-align: center;
    //
    //   .team-card {
    //     margin-bottom: 30px;
    //
    //     figure {
    //       margin-bottom: 30px;
    //     }
    //
    //     ul.nav {
    //       margin: 0;
    //
    //       li {
    //         &:first-child {
    //           margin-left: 0;
    //         }
    //         margin-left: 10px;
    //       }
    //     }
    //   }
    // }
  }

  //contattaci
  &.contacts {
    //.hero {
    //  background: $white;
    //}

    .absolute-pos {
      position: absolute!important;
    }

    .main-wrapp-contact {
      margin-top: 0 !important;
      overflow: hidden;
    }

    .relative {
      position: relative;
      top: 0;
      left: 0;
      width: $full;
      height: $full;
      min-height: 100vh;

      .full-screen {
        min-height: 100vh;

        @include media-breakpoint-down(md) {
          margin-bottom: 100px;
        }

        .main-wrapp {
          padding-bottom: 60px;

          @include media-breakpoint-down(md) {
            padding-bottom: 0px;
          }


          .headquarter-information {

            margin-top: 150px;
            margin-bottom: 60px;

            .title {
              h3 {
                color: $institutional;
                @extend .semibold;
              }
            }

            .address {
              .title {
                font-size: $rem-14px;
              }
              .house-name {
                font-size: $rem-13px;
                margin-top: 15px;
                color: $grey3;
              }

              .street {
                color: $grey3;
              }
            }
          }

          .contact-information {
            .title {
              h3 {
                color: $bold-text;
                @extend .semibold;
              }
            }
            .subtitle {
              color: $grey3;
              letter-spacing: 1.1px;
              margin-top: 10px;
            }

            .form-contact {
              margin-top: 60px;
              width: 80%;
              @include media-breakpoint-down(md) {
                width: $full;
              }

              .btn-send {
                background-color: $institutional!important;
              }

              div#mce-error-response {
                margin-top: 20px;
                padding: 0px 15px;
                color: #710101;
                font-size: $rem-14px;
                @extend .semibold;

                  a {
                    color: #710101;
                    text-decoration: underline;
                  }
              }
              div#mce-success-response {
                margin-top: 20px;
                padding: 0px 15px;
                color: $grey2;
                font-size: $rem-14px;
                @extend .semibold;

                  a {
                    color: $grey2;
                    text-decoration: underline;
                  }
              }
            }
          }
        }

        .map {
          position: absolute;
          top: 0;
          left: 0;
          width: $full;
          height: $full;
          @include media-breakpoint-down(md) {
            position: relative;
            height: inherit;
          }
          #map {
            @extend .extend-background-image;
          }
        }
      }
    }
  }

  // 404 not found
  &.not-found {
    position: relative;
    height: $full;

    .bg-not-found {
      width: $full;
      height: $full;
      position: absolute;
      top: 0;
      left: 0;
      @include background($gradient);
      text-align: center;
      color: $white;

      h1 {
        font-size: $rem-30px * 3;
        font-weight: 600;
      }

      .btnn-small {
        margin-top: 20px;
        background-color: $white;
        color: $institutional;
        line-height: 33px;
      }
    }
  }
}
