/*!
 *  RESPONSIVE
 */

@include media-breakpoint-down(lg) {

}
@include media-breakpoint-only(md) {

}

@include media-breakpoint-down(md) {
  main {
    .hero {
      .nav.social {
        margin: 60px 0 0 0;
      }
    }

    &.home {
      .header-text {
        margin-top: 50px;
      }

      .subtitle-header {
        margin-top: 50px;
        margin-bottom: 30px;
        font-size: $rem-24px;
      }
    }

    &.approccio {
      .appr-feature-bluebrint {
        .bluebrint-text {
          margin-bottom: 30px;
        }
      }
    }

    //Style of project list for entire site (this is not a section)


    &.projects {
      .full-sec {
        height: 400px;
      }
    }

    &.contacts {
      .map {
        height: 400px;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-only(sm) {

}

@include media-breakpoint-down(sm) {

  .btnn-medium {
    padding: 0px 15px;
    height: 35px;
    width: auto;
    min-width: 30px;
    @include border-radius(20px);
    text-transform: uppercase;
    font-size: $rem-10px;
    display: inline-block;
    background-color: $grey2;
    color: $white;
    border: none;
    line-height: 37px;

    &:hover, &:focus {
      cursor: pointer;
      background-color: $grey3;
      color: $white;
    }

    &.tag {
      background-color: $grey6;
      color: $grey2;
      margin: 7px;

      &:hover, &:focus {
        background-color: $grey4;
        color: $grey2;
      }
    }
  }

  main {
    &.home {
      .subtitle-header {
        font-size: $rem-20px;
      }

      .insights {
        .insights-col {
          margin-bottom: 40px;
        }
      }

      .home-projects {
        .prj-list {
          margin-bottom: 30px;
        }
      }
    }

    .contact-information {
      .btnn-medium {
        margin-top: 20px!important;
        width: 100%!important;
      }
    }

    &.projects {
      .full-sec {
        height: 300px;
      }
    }

    &.chi-siamo {
      .link-to-profile {
        a {
          width: 100%;
          text-align: center;
          margin-top: 15px;
        }
      }
    }

    &.contacts {
      .map {
        height: 350px;
      }

      .headquarter-information {
        .address {
          margin-top: 30px;
        }
      }
    }

    footer {
      .form-newsletter {
        .wrapp-input-mail {
          .form-control {
            height: 45px!important;
            padding: 0px 20px!important;
            font-size: $rem-14px;
            margin-bottom: 15px;
          }
        }
        .wrapp-submit {
          .btnn-medium {
            height: 40px!important;
            @include border-radius(60px);
          }
        }
      }

      .list-link-ft {
        margin-top: 20px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  main {

    &.projects {
      .full-sec {
        height: 250px;
      }
    }

    &.contacts {

      .map {
        height: 300px;
      }
      .headquarter-information {
        .mail-to {
          font-size: $rem-20px;
        }
      }
    }
  }
}
