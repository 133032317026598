/*!
 *  FORM
 */

 .btnn-medium {
   padding: 10px 30px;
   height: 40px;
   width: auto;
   min-width: 30px;
   @include border-radius(20px);
   text-transform: uppercase;
   font-size: $rem-12px;
   display: inline-block;
   background-color: $grey2;
   color: $white;
   border: none;

   &:hover, &:focus {
     cursor: pointer;
     background-color: $grey3;
     color: $white;
   }

   &.tag {
     background-color: $grey6;
     color: $grey2;
     margin: 10px;

     &:hover, &:focus {
       background-color: $grey4;
       color: $grey2;
     }
   }
 }
 .btnn-send {
    padding: 5px 30px;
    top: 5px;
    position: relative;
    height: 80%;
    width: 100%;
    min-width: 30px;
    @include border-radius(20px);
    text-transform: uppercase;
    font-size: 0.75rem;
    display: inline-block;
    background-color: #ffffff;
    color: $institutional;
    border: 2px solid transparent;
    @extend .semibold;

   &:hover, &:focus {
     cursor: pointer;
     color: $institutional;
     opacity: 0.8;
   }
 }

 .btnn-small {
   padding: 0px 30px;
   height: 30px;
   width: auto;
   min-width: 30px;
   @include border-radius(20px);
   text-transform: uppercase;
   font-size: $rem-10px;
   display: inline-block;
   background-color: $grey2;
   color: $white;
   border: none;
   line-height: 30px;

   &:hover, &:focus {
     cursor: pointer;
     background-color: $grey3;
     color: $white;
   }
 }

.c-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    p {
      color: $grey4;
      font-size: $rem-14px;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: -5px;
    left: 0;
    height: 25px;
    width: 25px;
    @include border-radius(25px);
    background-color: #eee;

}

/* On mouse-over, add a grey background color */
.c-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.c-checkbox input:checked ~ .checkmark {
    background-color: $institutional;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.c-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.c-checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


// INPUT AND LABEL
.group {
    position: relative;
    margin-bottom: 45px;
    color: $grey2;

    .form-input {
        display: block;
        width: $full;
        height: 40px;
        padding: 10px 10px 10px 5px;
        font-size: $rem-16px;
        box-shadow: inset 0 -1px 0 0 $grey6;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-apparence: none;
        -o-apperence: none;
        @include border-radius(0px);
        background-color: transparent;
        &:focus { outline: none; }

        // validation input
        &.error { box-shadow: inset 0 -2px 0 0 $error !important; }

        &.valid { box-shadow: inset 0 -2px 0 0 $institutional !important; }
    }

    .form-input:focus {
        box-shadow: inset 0 0 0 0 $grey3;
        @include transition(all 0.3s);
    }

    .form-input.white {
        border-bottom: none;
        box-shadow: inset 0 -1px 0 0 white;
        @include transition(all 0.3s);
    }

    .form-input.white:focus {
        box-shadow: inset 0 0 0 0 white;
        @include transition(all 0.3s);
    }

    label {
        color: $grey4;
        font-size: $rem-16px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 5px;
        @include transition(0.2s ease all);
        @include media-breakpoint-down(sm) {
          font-size: $rem-14px;
        }
    }

    label.white {
        color: $white;
    }

    .form-input:focus ~ label,
    .form-input:valid ~ label {
        top: -20px;
        font-size: $rem-14px;
        color: $institutional;
        @include media-breakpoint-down(sm) {
          top: -10px;
          font-size: $rem-12px;
        }
    }

    .form-input:focus ~ label.white,
    .form-input:valid ~ label.white {
        color: $institutional;
    }

    input[type="password"] {
        letter-spacing: 1px;
    }

    .bar {
        width: $full;
        position: relative;
        display: block;
        &:before,
        &:after {
            content: '';
            width: 0;
            height: 2px;
            bottom: 0;
            left: 0;
            position: absolute;
            background: $institutional;
            @include transition(0.2s ease all);
            &:before { left: $medium; }
            &:after { right: $medium; }
        }
    }

    .bar-institutional-hover {
        width: $full;
        position: relative;
        display: block;
        &:before,
        &:after {
            content: '';
            width: 0;
            height: 2px;
            bottom: 0;
            left: 0;
            position: absolute;
            background: $institutional;
            @include transition(0.2s ease all);
            &:before { left: $medium; }
            &:after { right: $medium; }
        }
    }

    .form-input:focus ~ .bar:before,
    .form-input:focus ~ .bar:after,
    .form-input:focus ~ .bar-institutional-hover:before,
    .form-input:focus ~ .bar-institutional-hover:after {
        width: $full;
        .form-input { border-bottom: none !important; }
    }

    .highlight {
        width: 0;
        height: 60%;
        position: absolute;
        top: 25%;
        left: 0;
        pointer-events: none;
        opacity: 0.5;
    }

    .form-input:focus ~ .highlight { @include animation(inputHighlighter 0.3s ease); }

    textarea {
      resize: none;
    }

    // Placeholder
    .form-input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
        font-weight: 300;
    }

    .form-input:-moz-placeholder, textarea:-moz-placeholder {
        font-weight: 300;
    }

    .form-input::-moz-placeholder, textarea::-moz-placeholder {
        font-weight: 300;
    }

    .form-input:-ms-input-placeholder, textarea:-ms-input-placeholder {
        font-weight: 300;
    }
}
