// Import Modules
@import '../_modules/link/link';

// Bower files
@import "../bower_components/bootstrap/scss/bootstrap.scss";
@import "../bower_components/animate.css/animate.min.css";
@import "../bower_components/swiper/dist/css/swiper.min.css";

///*** FONT ***///
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600,800,900');
//@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

///*** VARIABLES ***///
@import "common/variables";

///*** MIXIN ***///
@import "mixins/mixins";

///*** EXTENSION ***///
@import "extensions/extensions";

///*** GLOBAL ***///
@import "common/global";

///*** COMPONENTS ***///
@import "components/typography";
@import "components/forms";

///*** WRAPPER PAGES & POSTS ***///
@import "layouts/header";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/footer";

///*** RESPONSIVE ***///
@import "common/responsive";
