/*!
 *  FOOTER
 */

.menu-full {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include background($gradient-menu);
  z-index: 99;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;

  .icon-bk {
    position: fixed;
    top: 0px;
    right: 0;
    @include background-size(cover);
    background-repeat: no-repeat;
    width: 70%;
    height: $full;
  }

  .wrapp-icon {
    img {
      padding: 0px 30px;
      position: relative;
      top: 40px;

      @include media-breakpoint-down(sm) {
        padding: 0px;
      }
    }
  }
  .wrapp-link-list {
    ul {
      &.links {
        margin-top: 120px;
        text-align: left;
        li {
          @include media-breakpoint-down(md) {
            margin-bottom: 20px;
          }

          &:last-of-type {
            margin-bottom: 0px;
          }

          &.guide-down {
            margin-bottom: 30px;

            a {
              color: $white;
              border-bottom: 1px solid $white;
              margin-left: 30px;
              padding-bottom: 10px;
              @include media-breakpoint-down(sm) {
                margin-left: 0px;
              }

              img {
                width: 9px;
                margin-left: 10px;
              }
            }
          }

          .nav-link {
            font-size: $rem-40px + $rem-20px;
            text-transform: capitalize;
            @include media-breakpoint-down(md) {
              font-size: $rem-40px + $rem-10px;
            }
            @include media-breakpoint-down(sm) {
              font-size: $rem-40px;
            }
            @include media-breakpoint-down(xs) {
              font-size: $rem-34px;
            }

            color: $white;
            padding-left: 30px;
            @include media-breakpoint-down(sm) {
              padding: 0px;
            }
            text-transform: uppercase;
            @extend .semibold;
            line-height: initial;
            @include transition(all 0.4s);
            opacity: 0.4;

            .anchor {
              margin-left: 0px;
              @include transition(all 0.4s);
            }

            &:hover {
              @include transition(all 0.2s);
              opacity: 1;
              @include media-breakpoint-up(md) {
                .anchor {
                  margin-left: 50px;
                  @include transition(all 0.3s);
                }
              }
            }

            &.active {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .wrapp-social-list {

    ul {

      @include media-breakpoint-down(sm) {
        margin-left: 0px;
        margin-top: 40px;
      }
      &.social {
        li {
          margin-right: 35px;

          &:last-of-type {
            margin-right: 0px;
          }
        }
      }
    }
  }
}

footer {
   padding: 100px 0px 20px;
   @include background($gradient-footer);
    @extend .extend-footer;
    position: relative;
    overflow: hidden;
    z-index: 2;
    //margin-top: 100px;

    .bk-path {
      position: absolute;
      top: 10%;
      right: -10%;
      max-width: 500px;
      width: $full;
      height: $full;
    }

    .main-title {
      margin-bottom: 50px;
      h3 {
        color: $white;

        strong {
          @extend .semibold;
          color: $white;
        }
      }
    }

    .form-newsletter {

      .text-input {
        @include border-radius(100px!important);
        padding:  10px 150px 10px 20px;
        @include media-breakpoint-down(xs) {
        padding:  10px 90px 10px 20px;
        }
        background:  transparent;
        border: 2px solid white;
        color: $white;

        &:focus, &:hover {
          outline: none;
          @include box-shadow(0 0 0 0.2rem rgba(255, 255, 255, 0.25));
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $white;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $white;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $white;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $white;
        }
      }
      .inglobe {
        position:  absolute;
        right: 5px;
        height:  100%;
        width:  120px;
        @include media-breakpoint-down(xs) {
          width: 90px;
        }
      }

      div.mce_inline_error {
        position: absolute;
        top: -30px;
        background-color: transparent!important;
        margin: 0!important;
        font-size: 13px;
        letter-spacing: 0.6px;
        color: #772807!important;
      }

      div#mce-error-response {
          color: #772807;
          font-weight: 600;
          margin-top: 10px;
          margin-left: 10px;
      }
      div#mce-success-response {
        color: #ffffff;
        font-weight: 600;
        margin-top: 10px;
        margin-left: 10px;
      }
    }

    .wrapp-naviagtion  {

      .to-top {
        margin-top: 30px;
        @include media-breakpoint-down(md) {
          margin-top: 20px;
        }
      }
      .list-footer-link {

        .mob-mrg {
          @include media-breakpoint-down(md) {
            margin-top: 40px;
          }
        }
        .address {
          .address-wrap {
            .title {
              color: $white;
              @extend .semibold;
              font-size: $rem-14px;
            }
            .subtitle {
              p {
                @extend .light;
                color: $white;
                margin-bottom: 5px;

                a {
                  color: $white !important;
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .contact {
          .mail-wrap {
            .subtitle {
              p {
                @extend .light;
                color: $white;
                font-size: $rem-14px;
              }
            }
            .title {
              @extend .semibold;
              color: $white;
              font-size: $rem-14px;
              a {
                color: $white;
              }
            }
          }
          .social-wrapp {
            .title {
              @extend .semibold;
              color: $white;
              font-size: $rem-14px;
            }
            .list-social {
                li {
                  margin-right: 20px;
                  &:last-of-type {
                    margin-right: 0px;
                  }
                  a {
                    img {
                      max-width: 15px;
                      max-height: 15px;
                    }
                  }
                }
              }
          }
          .guide-wrapp {
            a {
              display: block;
              font-size: $rem-14px;
              @extend .semibold;
              color: $white;
              .wrapp-content {
                display: inline-block;
                border-bottom: 1px solid $white;
                padding-bottom: 10px;
                @include media-breakpoint-down(sm) {
                  margin-top: 20px;
                }


                img {
                  width: 7px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
        .list-link-ft{
          .lisks-footer {
            li {
              margin-bottom: 10px;
              a {
                text-transform: uppercase;
                color: $white;
                font-size: $rem-13px;
                @extend .semibold;
              }
            }
          }
        }
      }
    }

    .copyright {
      p {
        color: $white;
        font-size: $rem-14px;
        @extend .light;
      }
    }
 }
