/*!
 *  TYPOGRAPHY
 */

h1 {
  font-size: $rem-40px + $rem-34px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
  @include media-breakpoint-down(md){
    font-size: $rem-40px + $rem-30px;
  }
  @include media-breakpoint-down(sm){
    font-size: $rem-40px + $rem-26px;
  }
}

h2 {
  margin: 0;
  font-size: $rem-36px + $rem-10px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
  @include media-breakpoint-down(md){
    font-size: $rem-30px + $rem-16px;
  }
  @include media-breakpoint-down(sm){
    font-size: $rem-30px + $rem-22px;
  }
}

h3 {
  font-size: $rem-26px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
  @include media-breakpoint-down(md){
    font-size: $rem-22px;
  }
  @include media-breakpoint-down(sm){
    font-size: $rem-18px;
  }
}

h4 {
  font-size: $rem-20px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
  @include media-breakpoint-down(md){
    font-size: $rem-16px;
  }
  @include media-breakpoint-down(sm){
    font-size: $rem-12px;
  }
}

h5 {
  font-size: $rem-16px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
  @include media-breakpoint-down(md){
    font-size: $rem-14px;
  }
  @include media-breakpoint-down(sm){
    font-size: $rem-12px;
  }
}

h6 {
  font-size: $rem-12px;
  font-weight: 400;
  line-height: 1.3em;
  margin-top: 0;
  @include media-breakpoint-down(md){
    font-size: $rem-8px;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0px;
}

.institutional-background {

  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
    color: $white;
  }
}

p {
  margin: 0 0 10px 0;
  padding: 0;
  font-size: $rem-16px;
  color: $grey2;
  font-weight: 400;
  line-height: 1.5em;
}

.small {
  font-size: $rem-12px !important;
  line-height: 1.5em;
}

.medium {
  font-size: $rem-20px !important;
  line-height: 1.3em;
}

.large {
  font-size: $rem-26px !important;
  line-height: 1.3em;
}

.intestation {
  font-size: $rem-30px;
}

// .main-title {
//   padding-bottom: 40px;
//   text-align: center;
//
//   h3 {
//     font-weight: 600;
//   }
// }

.main-title-uppercase {
  text-align: center;

  h6 {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 30px;
  }
}

.side-heading {
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 0.9em;
  color: $grey6;
}

b, strong {
  color: $grey2;
  font-weight: 600;
}

a {
  color: $institutional;

  &:hover, &:focus {
    color: $grey2;
    text-decoration: none;
    outline: medium none;
    text-shadow: none;
  }
}

a.notext {
  text-decoration: none !important;
  padding: 0 5px;
}

blockquote {
  border-left: 4px solid $grey5 !important;
  font-size: $rem-18px !important;
  line-height: 1.3em;
  color: $grey3 !important;
  margin: 20px 0 !important;
  padding: 0 15px;
}

.note {
  font-size: $rem-16px;
  font-weight: 300;
}

abbr {
  font-size: $rem-24px;
}

.list-style-default {
  padding: 0;
  margin-left: 20px;
  list-style: initial;

  li {
    padding: 0;
    margin: 0 0 5px 0;
    float: none;
    color: $grey2;
  }
}

ul {
  margin: 0 0 0 20px;
  padding: 0;

  li {
    font-size: $rem-16px;
    color: $grey2;
    font-weight: 400;
    line-height: 1.5em;

    a {
      color: $institutional;
    }
  }
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 800;
}
.black {
  font-weight: 900;
}

.italic {
  font-style: italic;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.align-left {
  float: left;
  text-align: left !important;
}

.align-right {
  float: right;
  text-align: right !important;
}
