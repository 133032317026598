/*!
 *  STRUCTURE
 */

html {
  overflow-x: hidden;
  font-size: $full;
}

html.no-overflow {
  width: $full;
  height: $full;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // &:after {
  //   content: "";
  //   position: fixed;
  //   width: $full;
  //   height: $full;
  //   min-height: 600px;
  //   top: 0;
  //   left: 0;
  //   background-color: rgba($institutional, .95);
  //   @include transition(all 0.3s);
  //   z-index: 18;
  // }
}

html, body {
  height: $full;
  font-family: 'Raleway', sans-serif;
  @include text-size-adjust(none);
  @extend .reset-margin-padding;
  min-height: $full;
  background: $background_body;
  color: $grey2;
  line-height: 1.2em;
}

body {
  //height: auto !important;

  &.no-scroll {
    overflow: hidden;
  }
  //
  // .container-fluid {
  //   padding-left: 0 !important;
  //   padding-right: 0 !important;
  // }
}

main.contacts, main.projects {
  header {
    background-color: $white;

    nav {
      .nav-link {
        &:hover, &:focus, &.active {
          background-color: $grey2;
          color: $white;
        }
      }
    }
  }
}

// HR STYLE

hr {
  width: 45px;
  height: 5px;
  margin: 0px 0px 25px;
  background: $institutional;
  text-align: left;
}

.section-right-title {
  h3 {
    @include media-breakpoint-down(md) {
      font-size: $rem-20px;
    }
  }
}

.right-istitutional-title {
  h3 {
    font-size: $rem-36px;
    @include media-breakpoint-down(md) {
      font-size: $rem-20px;
    }
  }
}

// FIXED ARROW
.arrow-follower {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    @include media-breakpoint-down(md) {
      width: 35px;
      height: 35px;
    }
    z-index: 9;
    display: none;
    @include transition(all 0.4s ease-in-out);

    &.rotate-top {
      @include transform(rotate(-180deg));
      @include transition(all 0.4s ease-in-out);
    }
}


// MENU CIRCULR
// .menu-circle {
//     position: absolute;
//     top: 0;
//     right: -100px;
//     width: 100px;
//     height: 100px;
//     background: white;
//     @include transform(scale(1));
//     z-index: 1;
//     border-radius: 100px;
//     @include border-radius(100px);
//     @include transition(all 1s);
//
//     &.active {
//       @include transform(scale(40));
//       @include transition(all 1s);
//     }
// }

/* ---------------------------- Placeholder ----------------------------------- */
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $white;
}
input::-moz-placeholder { /* Firefox 19+ */
  color: $white;
}
input:-ms-input-placeholder { /* IE 10+ */
  color: $white;
}
input:-moz-placeholder { /* Firefox 18- */
  color: $white;
}

/* ---------------------------- Selections ----------------------------------- */
::-moz-selection {
  background: $institutional;
  color: $white;
}

::selection {
  background: $institutional;
  color: $white;
}

 .main-wrapp {
   width: $full;
   height: $full;
   background-color: $white;
   // margin-top: 500px;
  //  @include media-breakpoint-down(md) {
  //    margin-top: 300px;
  //  }
  // //  @include media-breakpoint-down(sm) {
  // //    margin-top: 150px;
  // //  }
   position: relative;
   z-index: 2;
 }

/* ---------------------------- Reset ----------------------------------- */
.notransition {
  @include transition(none !important);
}

* {
  @extend .reset-margin-padding;
  text-shadow: none;
  outline: medium none !important;
}

*:before, *:after {
  @extend .reset-margin-padding;
  @include box-sizing(border-box);
}

*:hover {
  @include transition(0s);
}

a:hover, a:focus {
  text-decoration: none;
}

.clear {
  clear: both;
}

.reset-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.reset-margin-padding {
  margin: 0;
  padding: 0;
}

.full-width-height {
  width: $full;
  height: $full;
}

.padding-top,
.padding-right,
.padding-bottom,
.padding-left {
  padding-top: 40px;
}

input {
  border: none;
  outline: none;
}

img {
  max-width: $full;
}

/* ---------------------------- Basic components ----------------------------------- */
.wrapp-spinner {
  width: $full;
  height: $full;
  position: fixed;
  z-index: 9999;
  @include background($gradient);

  .spinner {
    width: 40px;
    height: 40px;
    position: absolute;
    top: $medium;
    left: $medium;
    margin-top: -20px;
    margin-left: -20px;
    text-align: center;
    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear;

    .dot1, .dot2 {
      width: 60%;
      height: 60%;
      display: inline-block;
      position: absolute;
      top: 0;
      background-color: $white;
      border-radius: $full;

      -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
      animation: sk-bounce 2.0s infinite ease-in-out;
    }

    .dot2 {
      top: auto;
      bottom: 0;
      -webkit-animation-delay: -1.0s;
      animation-delay: -1.0s;
    }
  }

  @-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
  @keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bounce {
    0%, 100% {
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
      }
  }
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}

.slider-carousel {
  text-align: center;

  .slick-slide img {
    display: inline-block;
  }

  .slick-prev:before, .slick-next:before {
    color: $grey3;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-dots {
    bottom: -46px;
  }
}

hr {
  &.xs-small-fillet {
    width: 15px;
    border-top: 1px solid $institutional;
    display: inline-block;
    vertical-align: middle;
  }

  &.small-fillet {
    width: 30px;
    border-top: 1px solid $institutional;
    display: inline-block;
    vertical-align: middle;
  }

  &.medium-fillet {
    width: 70px;
    border-top: 1px solid $institutional;
    display: inline-block;
    vertical-align: middle;
  }

  &.large-fillet {
    width: 110px;
    border-top: 1px solid $institutional;
    display: inline-block;
    vertical-align: middle;
  }

  &.full-fillet {
    width: $full;
    border-top: 1px solid $institutional;
    display: inline-block;
    vertical-align: middle;
  }
}

ul.floating-elements {
  width: $full;
  display: inline-block;
  list-style-type: none !important;
  margin: 0 !important;

  li {
    margin: 0 0 20px 0 !important;
    float: left !important;
    color: inherit !important;
  }
}

.text-noresult {
  text-align: center;
  font-size: $rem-20px;
  font-weight: 300;
  color: $grey2;
}

.delay0 {
  -webkit-animation-delay: -0.1s;
  animation-delay: 0.1s;
}

.delay {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.delay1 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.delay2 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.delay3 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.delay4 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.delay5 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.delay6 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.delay7 {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.delay8 {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.delay9 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

.delay10 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

.delay11 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

.delay12 {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
}

// tooltip
.tooltip-inner {
  background-color: $grey1;
  color: $white;
}

.tooltip.top .tooltip-arrow {
  border-top-color: $grey1;
}

// modal
.modal {

  .modal-title {
    font-weight: 400;
    color: $institutional;
  }

  .text-modal {
    color: $grey2;
    font-size: $rem-16px;
    line-height: 1.6rem;

    a {
      color: $institutional;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ul {
    margin-left: 20px;
  }
}




// ANIMATION
@-webkit-keyframes cloud {
    0% {
        -webkit-transform: translate3d(1%, 0, 0) rotateZ(0.5deg);
        -moz-transform: translate3d(1%, 0, 0) rotateZ(0.5deg);
        transform: translate3d(1%, 0, 0) rotateZ(0.5deg);
    }
    100% {
        -webkit-transform: translate3d(-1%, 0, 0) rotateZ(-0.5deg);
        -moz-transform: translate3d(-1%, 0, 0) rotateZ(-0.5deg);
        transform: translate3d(-1%, 0, 0) rotateZ(-0.5deg);
    }
}

@-moz-keyframes cloud {
    0% {
        -webkit-transform: translate3d(1%, 0, 0) rotateZ(0.5deg);
        -moz-transform: translate3d(1%, 0, 0) rotateZ(0.5deg);
        transform: translate3d(1%, 0, 0) rotateZ(0.5deg);
    }
    100% {
        -webkit-transform: translate3d(-1%, 0, 0) rotateZ(-0.5deg);
        -moz-transform: translate3d(-1%, 0, 0) rotateZ(-0.5deg);
        transform: translate3d(-1%, 0, 0) rotateZ(-0.5deg);
    }
}

@-ms-keyframes cloud {
    0% {
        -webkit-transform: translate3d(1%, 0, 0) rotateZ(0.5deg);
        -moz-transform: translate3d(1%, 0, 0) rotateZ(0.5deg);
        transform: translate3d(1%, 0, 0) rotateZ(0.5deg);
    }
    100% {
        -webkit-transform: translate3d(-1%, 0, 0) rotateZ(-0.5deg);
        -moz-transform: translate3d(-1%, 0, 0) rotateZ(-0.5deg);
        transform: translate3d(-1%, 0, 0) rotateZ(-0.5deg);
    }
}

@keyframes cloud {
    0% {
        -webkit-transform: translate3d(1%, 0, 0) rotateZ(0.5deg);
        -moz-transform: translate3d(1%, 0, 0) rotateZ(0.5deg);
        transform: translate3d(1%, 0, 0) rotateZ(0.5deg);
    }
    100% {
        -webkit-transform: translate3d(-1%, 0, 0) rotateZ(-0.5deg);
        -moz-transform: translate3d(-1%, 0, 0) rotateZ(-0.5deg);
        transform: translate3d(-1%, 0, 0) rotateZ(-0.5deg);
    }
}

@-webkit-keyframes ballon {
    0% {
        -webkit-transform: translateY(20px) rotateZ(0.1deg);
        -moz-transform: translateY(20px) rotateZ(0.1deg);
        transform: translateY(20px) rotateZ(0.1deg);
    }
    100% {
        -webkit-transform: translateY(-20px) rotateZ(-0.1deg);
        -moz-transform: translateY(-20px) rotateZ(-0.1deg);
        transform: translateY(-20px) rotateZ(-0.1deg);
    }
}

@-moz-keyframes ballon {
    0% {
        -webkit-transform: translateY(20px) rotateZ(0.1deg);
        -moz-transform: translateY(20px) rotateZ(0.1deg);
        transform: translateY(20px) rotateZ(0.1deg);
    }
    100% {
        -webkit-transform: translateY(-20px) rotateZ(-0.1deg);
        -moz-transform: translateY(-20px) rotateZ(-0.1deg);
        transform: translateY(-20px) rotateZ(-0.1deg);
    }
}

@-ms-keyframes ballon {
    0% {
        -webkit-transform: translateY(20px) rotateZ(0.1deg);
        -moz-transform: translateY(20px) rotateZ(0.1deg);
        transform: translateY(20px) rotateZ(0.1deg);
    }
    100% {
        -webkit-transform: translateY(-20px) rotateZ(-0.1deg);
        -moz-transform: translateY(-20px) rotateZ(-0.1deg);
        transform: translateY(-20px) rotateZ(-0.1deg);
    }
}

@keyframes ballon {
     0% {
        -webkit-transform: translateY(20px) rotateZ(0.1deg);
        -moz-transform: translateY(20px) rotateZ(0.1deg);
        transform: translateY(20px) rotateZ(0.1deg);
    }
    100% {
        -webkit-transform: translateY(-20px) rotateZ(-0.1deg);
        -moz-transform: translateY(-20px) rotateZ(-0.1deg);
        transform: translateY(-20px) rotateZ(-0.1deg);
    }
}



// EXPLORER tag-list
@media all and (-ms-high-contrast:none) {
/* IE10 */
    #circular, #ellipsis {
      height: 300px;
    }

    .wrapp-card .card-image img {
      height: 250px!important;
    }
}

/* IE11 */
*::-ms-backdrop, #circular, #ellipsis {
  height: 300px;
}
*::-ms-backdrop, .wrapp-card .card-image img {
      height: 250px!important;
}
