/*!
 *  HEADER
 */

header {
  width: $full;
  position: absolute;
  top: 0;
  left: 0;

  // &.fixed {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  // }

  .navigation {
    @include media-breakpoint-up(xl) {
      position: fixed;
    }
    position: absolute;
    top: 0;
    left: 0;
    width: $full;
    height: auto;
    z-index: 999;

    &.contacts-nav {
      position: absolute;

      .trademark {
        a {
          padding-left: 0px;
        }
      }
    }

    .trademark {
      padding: 0px 20px;
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(lg) {
        position: relative!important;
      }

        .midnightHeader {
          .midnightInner {
            overflow: hidden!important;
          }
          &.institutional {
            .midnightInner {
              a {
                #svg-white path {
                  fill: $institutional;
                }
              }
            }
          }
        }
        a {
          color: $grey2;
          padding-left: 20px;
          @media (max-width: 1300px) and (min-width: 1100px){
            padding-left: 15px;
          }
          padding-top: 15px;
          display: block;
          svg {
            @include transition(all 0.4s ease-in-out);
            width: 150px;

            @include media-breakpoint-down(md) {
              width: 110px;
            }
            @include media-breakpoint-down(sm) {
              width: 90px;
            }

            &#svg-color {
              fill: $institutional;
            }
            &#svg-white {
              fill: $white;


              &.minimize {
                width: 100px;
                @media (max-width: 1300px) and (min-width: 1100px){
                  width: 90px;
                }
              }

              path {
                &.fillinst {
                  fill: $institutional;
                }
              }
            }
          }
        }
    }

    nav {
      .nav-link {
        font-size: $rem-10px;
        font-weight: 600;
        text-transform: uppercase;
        color: $grey2;
        padding: 5px 10px;
        margin: 0 5px;
        @include border-radius(20px);

        &:hover, &:focus, &.active {
          background-color: $white;
        }
      }
    }
  }
}

  .wrapper-menu {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    text-align: right;

    .fix-to-pos {
    display: inline-block;
    padding: 10px;
    width: auto;
    height: 110px;
    z-index: 999;
    background: $white;
    position: relative;
    @include media-breakpoint-down(lg) {
      position: fixed;
      top: 0;
      right: 0;
    }

    @include media-breakpoint-down(md) {
      height: 100px;
      padding: 5px;
    }
    @include transition(all 0.6ms);


    .menu-toggle {
      width: 26px;
      @include media-breakpoint-down(md) {
        width: 20px;
      }
      height: 20px;
      cursor: pointer;
      z-index: 999;
      position: relative;
      margin: 0 auto;

      .one,
      .two,
      .three {
          width: $full;
          height: 2px;
          background: $institutional;
          margin: 6px auto 0px;
          backface-visibility: hidden;
          @include transition-duration(0.3s);
      }
      .two {
        width: 70%;
        margin-left: auto;
        margin-right: 0;
      }

      &.on {
          .one {
            @include transform(rotate(45deg) translate(6px, 6px));
              background: $white !important;
          }

          .two { opacity: 0; }

          .three {
              @include transform (rotate(-45deg) translate(5px, -5px));
              background: $white !important;
          }
      }
    }

    .vertical-txt {
      transform: rotate(90deg);
      margin-top: 25px;
      width: 100%;
      text-align: center;
      display: block;
      color: $institutional;
      font-size: $rem-12px;
      @include media-breakpoint-down(md) {
        font-size: $rem-10px;
      }
    }

    // ACTIVE CLASS FOR fix-to-pos
    &.active {
      @include transition(all 0.6ms);
      background: transparent;

      .vertical-txt {
        color: $white;
        margin-top: 40px;
      }
    }
  }
}
