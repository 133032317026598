/*!
 *  EXTENSION
 */

.extend-hero {
  width: $full;
  min-height: 500px;
  background-color: $grey6;

  h1 {
    font-weight: 600;
  }

  &.full {
    height: 100%;
    // height: calc(100vh - 120px);
    .row {
      // height: calc(100vh - 120px);
      height: 100%;
    }
  }

  &.half {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    // height: calc(70vh - 120px);
    .row {
      height: 100%;
      // height: calc(70vh - 120px);
    }
  }

  &.smaller {
    // height: calc(40vh - 120px);
    height: 100%;
    background-color: $white!important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .row {
      height: 100%;
      // height: calc(40vh - 120px);
    }
  }


  .nav.social {
    margin: 100px 0 0 0;

    li {
      margin-right: 5px;
    }
  }
}

.extend-background-image {
  @include background-size(cover);
  background-position: center;
  background-repeat: no-repeat;
  width: $full;
  height: $full;
  position: absolute;
  top: 0px;
  left: 0px;
}

.extend-footer {
  .main-title {
    h3 {
      text-align: left;
    }
  }
  .form-newsletter {
    .wrapp-input-mail {
      .form-control {
        background-color: #d2d2d2;
        border-radius: 50px;
        height: 50px;
        padding: 10px 20px;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: white;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: white;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: white;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: white;
        }
      }
    }
    .wrapp-submit {
      .btnn-medium {
        height: 50px;
        @include border-radius(60px);
      }
    }
  }
  .list-footer-link {
    padding: 60px 0px 0px;

    .lisks-footer {
      margin-left: 0px;
      &:first-child() {
        margin-right: 40px;
      }
      li {
        font-size: $rem-14px;
        a{
          color: $grey4;
        }
      }
    }
  }
  .copyright {
    padding-top: 40px;
    @include media-breakpoint-down(md) {
      padding-top: 30px;
    }
    p {
      color: $grey4;
    }
  }
}

.extend-project-section {

          .wrapp-all-card {
            margin-top: 60px;
            margin-bottom: 30px;

            .wrapp-card {
              text-align: center;

              .card-image {
                position: relative;
                overflow: hidden;
                @include border-radius(5px);
                display: inline-block;


                .hover-content {
                  position: absolute;
                  top: 0;
                  text-align: left;
                  width: 100%;
                  height: 100%;
                  @include background-custom((-135deg, rgba(234, 244, 129, 1) 0%, rgba(149, 237, 165, 0.9) 49%, rgb(71, 230, 198) 100%));
                  padding: 20px;

                  .wrapp-content {
                    .title {
                      color: $white;
                      letter-spacing: 1.1px;

                      h4 {
                        @include media-breakpoint-down(md) {
                          font-size: $rem-18px;
                        }
                      }
                    }
                    .description {
                      p {
                        font-size: $rem-14px;
                        @include media-breakpoint-only(lg) {
                          font-size: $rem-11px;
                        }
                        @include media-breakpoint-down(md) {
                          font-size: $rem-14px;
                        }
                        @include media-breakpoint-down(sm) {
                          font-size: $rem-16px;
                        }
                        color: $white;
                        @extend .light;
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }

          }
          .swiper-pagination-bullets {
            // @include media-breakpoint-up(lg) {
            //   display: none;
            // }
            bottom: -10px!important;
          }

          .swiper-pagination-bullet-active {
            background: $institutional!important;
          }
}
